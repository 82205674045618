import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69'),
	() => import('./nodes/70'),
	() => import('./nodes/71'),
	() => import('./nodes/72'),
	() => import('./nodes/73'),
	() => import('./nodes/74'),
	() => import('./nodes/75'),
	() => import('./nodes/76'),
	() => import('./nodes/77'),
	() => import('./nodes/78'),
	() => import('./nodes/79'),
	() => import('./nodes/80'),
	() => import('./nodes/81'),
	() => import('./nodes/82'),
	() => import('./nodes/83'),
	() => import('./nodes/84'),
	() => import('./nodes/85'),
	() => import('./nodes/86'),
	() => import('./nodes/87'),
	() => import('./nodes/88'),
	() => import('./nodes/89'),
	() => import('./nodes/90'),
	() => import('./nodes/91'),
	() => import('./nodes/92'),
	() => import('./nodes/93'),
	() => import('./nodes/94'),
	() => import('./nodes/95'),
	() => import('./nodes/96'),
	() => import('./nodes/97'),
	() => import('./nodes/98'),
	() => import('./nodes/99'),
	() => import('./nodes/100'),
	() => import('./nodes/101'),
	() => import('./nodes/102'),
	() => import('./nodes/103'),
	() => import('./nodes/104'),
	() => import('./nodes/105'),
	() => import('./nodes/106'),
	() => import('./nodes/107'),
	() => import('./nodes/108'),
	() => import('./nodes/109'),
	() => import('./nodes/110'),
	() => import('./nodes/111'),
	() => import('./nodes/112'),
	() => import('./nodes/113'),
	() => import('./nodes/114'),
	() => import('./nodes/115'),
	() => import('./nodes/116'),
	() => import('./nodes/117'),
	() => import('./nodes/118'),
	() => import('./nodes/119'),
	() => import('./nodes/120'),
	() => import('./nodes/121'),
	() => import('./nodes/122'),
	() => import('./nodes/123'),
	() => import('./nodes/124'),
	() => import('./nodes/125'),
	() => import('./nodes/126'),
	() => import('./nodes/127'),
	() => import('./nodes/128'),
	() => import('./nodes/129'),
	() => import('./nodes/130'),
	() => import('./nodes/131'),
	() => import('./nodes/132'),
	() => import('./nodes/133'),
	() => import('./nodes/134'),
	() => import('./nodes/135'),
	() => import('./nodes/136'),
	() => import('./nodes/137'),
	() => import('./nodes/138'),
	() => import('./nodes/139'),
	() => import('./nodes/140'),
	() => import('./nodes/141'),
	() => import('./nodes/142'),
	() => import('./nodes/143'),
	() => import('./nodes/144'),
	() => import('./nodes/145'),
	() => import('./nodes/146'),
	() => import('./nodes/147'),
	() => import('./nodes/148'),
	() => import('./nodes/149'),
	() => import('./nodes/150'),
	() => import('./nodes/151'),
	() => import('./nodes/152'),
	() => import('./nodes/153'),
	() => import('./nodes/154'),
	() => import('./nodes/155'),
	() => import('./nodes/156'),
	() => import('./nodes/157'),
	() => import('./nodes/158'),
	() => import('./nodes/159'),
	() => import('./nodes/160'),
	() => import('./nodes/161'),
	() => import('./nodes/162'),
	() => import('./nodes/163'),
	() => import('./nodes/164'),
	() => import('./nodes/165'),
	() => import('./nodes/166'),
	() => import('./nodes/167'),
	() => import('./nodes/168'),
	() => import('./nodes/169'),
	() => import('./nodes/170'),
	() => import('./nodes/171'),
	() => import('./nodes/172'),
	() => import('./nodes/173'),
	() => import('./nodes/174'),
	() => import('./nodes/175'),
	() => import('./nodes/176'),
	() => import('./nodes/177'),
	() => import('./nodes/178'),
	() => import('./nodes/179'),
	() => import('./nodes/180'),
	() => import('./nodes/181'),
	() => import('./nodes/182'),
	() => import('./nodes/183'),
	() => import('./nodes/184'),
	() => import('./nodes/185'),
	() => import('./nodes/186'),
	() => import('./nodes/187'),
	() => import('./nodes/188'),
	() => import('./nodes/189'),
	() => import('./nodes/190'),
	() => import('./nodes/191'),
	() => import('./nodes/192'),
	() => import('./nodes/193'),
	() => import('./nodes/194'),
	() => import('./nodes/195'),
	() => import('./nodes/196'),
	() => import('./nodes/197'),
	() => import('./nodes/198'),
	() => import('./nodes/199'),
	() => import('./nodes/200'),
	() => import('./nodes/201'),
	() => import('./nodes/202'),
	() => import('./nodes/203'),
	() => import('./nodes/204'),
	() => import('./nodes/205'),
	() => import('./nodes/206'),
	() => import('./nodes/207'),
	() => import('./nodes/208'),
	() => import('./nodes/209'),
	() => import('./nodes/210'),
	() => import('./nodes/211'),
	() => import('./nodes/212'),
	() => import('./nodes/213'),
	() => import('./nodes/214'),
	() => import('./nodes/215'),
	() => import('./nodes/216'),
	() => import('./nodes/217'),
	() => import('./nodes/218'),
	() => import('./nodes/219'),
	() => import('./nodes/220'),
	() => import('./nodes/221'),
	() => import('./nodes/222'),
	() => import('./nodes/223'),
	() => import('./nodes/224'),
	() => import('./nodes/225'),
	() => import('./nodes/226'),
	() => import('./nodes/227')
];

export const server_loads = [0,25,28,27,29,30,31,33,36,2,4,5,6,7,8,9,10,11,12,13,14,15,16,17,23,18,20,24];

export const dictionary = {
		"/": [~40],
		"/admin": [~123,[25],[26]],
		"/admin/companies": [~124,[25],[26]],
		"/admin/companies/[companySlug]": [~125,[25],[26]],
		"/admin/companies/[companySlug]/edit": [~126,[25],[26]],
		"/admin/companies/[companySlug]/edit/accesses": [127,[25],[26]],
		"/admin/companies/[companySlug]/edit/licenses": [128,[25],[26]],
		"/admin/companies/[companySlug]/projects": [~129,[25],[26]],
		"/admin/companies/[companySlug]/users": [~130,[25],[26]],
		"/admin/devtools": [131,[25],[26]],
		"/admin/emails": [~132,[25],[26]],
		"/admin/emails/email-dashboard": [~133,[25],[26]],
		"/admin/event-log": [~134,[25],[26]],
		"/admin/licenses": [135,[25],[26]],
		"/admin/licenses/susan": [~136,[25],[26]],
		"/admin/new-company": [137,[25],[26]],
		"/admin/questions": [~138,[25],[26]],
		"/admin/questions/add": [~148,[25],[26]],
		"/admin/questions/options": [149,[25],[26]],
		"/admin/questions/options/add": [~153,[25],[26]],
		"/admin/questions/options/[optionId]": [~150,[25],[26]],
		"/admin/questions/options/[optionId]/edit": [~151,[25],[26]],
		"/admin/questions/options/[optionId]/localizations/[locale]": [~152,[25],[26]],
		"/admin/questions/restructure": [~154,[25],[26]],
		"/admin/questions/sets": [~155,[25],[26]],
		"/admin/questions/sets/[questionSetId]": [~156,[25,28],[26]],
		"/admin/questions/sets/[questionSetId]/access": [~157,[25,28],[26]],
		"/admin/questions/sets/[questionSetId]/edit-details": [~158,[25,28],[26]],
		"/admin/questions/sets/[questionSetId]/print": [~159],
		"/admin/questions/[questionId]": [139,[25,27],[26]],
		"/admin/questions/[questionId]/details": [140,[25,27],[26]],
		"/admin/questions/[questionId]/edit": [141,[25,27],[26]],
		"/admin/questions/[questionId]/localizations": [142,[25,27],[26]],
		"/admin/questions/[questionId]/localizations/[locale]": [~143,[25,27],[26]],
		"/admin/questions/[questionId]/options": [~144,[25,27],[26]],
		"/admin/questions/[questionId]/scores": [~145,[25,27],[26]],
		"/admin/questions/[questionId]/ungc": [~146,[25,27],[26]],
		"/admin/questions/[questionId]/update-ungc": [~147,[25,27],[26]],
		"/admin/sent-emails/[email]/[emailType]": [~160],
		"/admin/suggested-follow-ups": [~161,[25],[26]],
		"/admin/suggested-follow-ups/[questionId]": [~162,[25,29],[26]],
		"/admin/suggested-follow-ups/[questionId]/localizations": [~163,[25,29],[26]],
		"/admin/taxonomy": [164,[25,30],[26]],
		"/admin/taxonomy/version": [184,[25,30],[26]],
		"/admin/taxonomy/version/[versionId]": [~185,[25,30],[26]],
		"/admin/taxonomy/year": [~186,[25,30],[26]],
		"/admin/taxonomy/[versionId]": [165,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/activities": [166,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/activities/[activityId]": [~167,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/activity-based": [~168,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/categories": [~169,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/categories/edit": [~170,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/company-wide": [~171,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/expiration": [~172,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/kpi-texts": [173,[25,30,31,32],[26]],
		"/admin/taxonomy/[versionId]/kpi-texts/[kpi]": [~174,[25,30,31,32],[26]],
		"/admin/taxonomy/[versionId]/question-sets": [~178,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/question-sets/[setId]": [~179,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]": [~175,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]/localizations/[locale]": [~176,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/question/[setId]/[questionId]/update-scoring": [~177,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/reusable-group": [~180,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/reusable-group/[groupId]": [~181,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/sectors": [182,[25,30,31],[26]],
		"/admin/taxonomy/[versionId]/sectors/[sectorId]": [~183,[25,30,31],[26]],
		"/admin/transparency-act-steps": [~187,[25],[26]],
		"/admin/transparency-act-steps/add": [191,[25],[26]],
		"/admin/transparency-act-steps/checklist-item": [~192,[25],[26]],
		"/admin/transparency-act-steps/checklist-item/[checklistItemId]": [~193,[25],[26]],
		"/admin/transparency-act-steps/checklist-item/[checklistItemId]/add-localization": [~194,[25],[26]],
		"/admin/transparency-act-steps/[taStepId]": [~188,[25],[26]],
		"/admin/transparency-act-steps/[taStepId]/add-checklist-item": [189,[25],[26]],
		"/admin/transparency-act-steps/[taStepId]/add-localization": [~190,[25],[26]],
		"/admin/users": [~195,[25],[26]],
		"/admin/users/[userId]": [~196,[25],[26]],
		"/admin/users/[userId]/edit": [~197,[25],[26]],
		"/admin/workers": [~198,[25,33],[26]],
		"/admin/workers/mailgun_status_worker": [~199,[25,33],[26]],
		"/admin/workers/message_email_worker": [~200,[25,33],[26]],
		"/admin/workers/notification_worker": [~201,[25,33],[26]],
		"/admin/workers/project_worker": [~202,[25,33],[26]],
		"/admin/workers/send_email_worker": [~203,[25,33],[26]],
		"/admin/workers/suggested_follow_up_available_worker": [~204,[25,33],[26]],
		"/admin/workers/survey_deadline_reminder_worker": [~205,[25,33],[26]],
		"/data-processing-agreement": [206,[34]],
		"/login": [207],
		"/logout": [208],
		"/maintenance": [209],
		"/notifications": [~210,[35]],
		"/survey-invitation/[invitationId]": [~211,[36]],
		"/survey-invitation/[invitationId]/access-requested": [~212,[36]],
		"/survey-invitation/[invitationId]/company-created": [~213,[36]],
		"/survey-invitation/[invitationId]/declined": [215,[36]],
		"/survey-invitation/[invitationId]/decline": [214,[36]],
		"/survey-invitation/[invitationId]/forwarded": [217,[36]],
		"/survey-invitation/[invitationId]/forward": [216,[36]],
		"/survey-invitation/[invitationId]/new-company": [~218,[36]],
		"/survey-invitation/[invitationId]/new-company/access-requested": [219,[36]],
		"/survey-invitation/[invitationId]/no-access": [~220,[36]],
		"/survey-invitation/[invitationId]/request-access/[companyId]": [~221,[36]],
		"/survey-invitation/[invitationId]/request-access/[companyId]/access-requested": [~222,[36]],
		"/survey-invitation/[invitationId]/select-company": [~223,[36]],
		"/terms-of-use": [224,[37]],
		"/user": [225,[38],[39]],
		"/user/access-tokens": [~226,[38],[39]],
		"/user/access-tokens/create": [~227,[38],[39]],
		"/[companySlug]": [~41,[2],[3]],
		"/[companySlug]/admin/add-company": [~42,[2],[3]],
		"/[companySlug]/company": [~43,[2,4],[3]],
		"/[companySlug]/company/accesses": [~44,[2,4],[3]],
		"/[companySlug]/company/assessments": [~45,[2,4],[3]],
		"/[companySlug]/company/documents": [~46,[2,4],[3]],
		"/[companySlug]/company/edit": [47,[2,4],[3]],
		"/[companySlug]/company/licenses": [~48,[2,4],[3]],
		"/[companySlug]/company/overview": [~49,[2,4],[3]],
		"/[companySlug]/company/saq-results": [~50,[2,4],[3]],
		"/[companySlug]/company/users": [~51,[2,4],[3]],
		"/[companySlug]/customers": [~52,[2],[3]],
		"/[companySlug]/customers/[customerId]": [~53,[2],[3]],
		"/[companySlug]/customers/[customerId]/overview": [54,[2,5],[3]],
		"/[companySlug]/customers/[customerId]/overview/messages": [~55,[2,5],[3]],
		"/[companySlug]/follow-ups": [56,[2,6],[3]],
		"/[companySlug]/follow-ups/received": [~57,[2,6],[3]],
		"/[companySlug]/follow-ups/received/[senderId]/[questionId]": [~58,[2,6],[3]],
		"/[companySlug]/follow-ups/sent": [~59,[2,6],[3]],
		"/[companySlug]/follow-ups/sent/[receiverId]/[questionId]": [~60,[2,6],[3]],
		"/[companySlug]/projects": [~61,[2,7],[3]],
		"/[companySlug]/projects/add": [~69,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/(main)": [62,[2,7,8],[3]],
		"/[companySlug]/projects/[projectId]/(main)/chain/[chainId]": [~63,[2,7,8],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/edit-suppliers-list": [~64,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/preview": [~65,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/preview/print": [~66],
		"/[companySlug]/projects/[projectId]/(sub-pages)/send-survey": [~67,[2,7],[3]],
		"/[companySlug]/projects/[projectId]/(sub-pages)/settings": [~68,[2,7],[3]],
		"/[companySlug]/saq/[questionSetId]": [~70,[9]],
		"/[companySlug]/saq/[questionSetId]/answer/[...questionPath]": [~71,[9]],
		"/[companySlug]/saq/[questionSetId]/finish": [~72,[10]],
		"/[companySlug]/suppliers": [~73,[2,11],[3]],
		"/[companySlug]/suppliers/add": [~88,[2,11],[3]],
		"/[companySlug]/suppliers/upload": [~89,[2,11],[3]],
		"/[companySlug]/suppliers/upload/[uploadId]": [~90,[2,11],[3]],
		"/[companySlug]/suppliers/upload/[uploadId]/status": [~91,[2,11],[3]],
		"/[companySlug]/suppliers/[supplierId]": [~74,[2,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/edit": [~75,[2,11],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview": [76,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/documents": [~77,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/documents/[uploadId]": [~78,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/follow-ups": [~79,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/history": [80,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/messages": [~81,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results": [~82,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]": [~83,[2,11,12],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/print": [~84],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/ungc": [85,[2,11,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/ungc/category": [~86,[2,11,12,13],[3]],
		"/[companySlug]/suppliers/[supplierId]/overview/results/[projectId]/ungc/themes": [~87,[2,11,12,13],[3]],
		"/[companySlug]/surveys": [~92,[2],[3]],
		"/[companySlug]/surveys/[surveyId]": [~93,[14]],
		"/[companySlug]/surveys/[surveyId]/access-requested": [~94],
		"/[companySlug]/surveys/[surveyId]/answer": [~95,[14,15]],
		"/[companySlug]/surveys/[surveyId]/answer/[...questionPath]": [~96,[14,15]],
		"/[companySlug]/surveys/[surveyId]/finish": [~97,[16]],
		"/[companySlug]/surveys/[surveyId]/finish/thank-you": [~98,[16]],
		"/[companySlug]/surveys/[surveyId]/no-access": [~99],
		"/[companySlug]/taxonomy": [~100,[17]],
		"/[companySlug]/taxonomy/overview": [~118,[17,23]],
		"/[companySlug]/taxonomy/overview/import-report": [~119,[17,23]],
		"/[companySlug]/taxonomy/[reportId]": [101,[18]],
		"/[companySlug]/taxonomy/[reportId]/company/minimumSafeguards": [~102,[18,19]],
		"/[companySlug]/taxonomy/[reportId]/company/pais": [~103,[18,19]],
		"/[companySlug]/taxonomy/[reportId]/evaluate": [~104,[18]],
		"/[companySlug]/taxonomy/[reportId]/evaluate/[entityId]": [~105,[18,20]],
		"/[companySlug]/taxonomy/[reportId]/evaluate/[entityId]/[activityId]": [~106,[18,20]],
		"/[companySlug]/taxonomy/[reportId]/financial": [~107,[18,21]],
		"/[companySlug]/taxonomy/[reportId]/financial/activity": [~109,[18,21]],
		"/[companySlug]/taxonomy/[reportId]/financial/layout": [110,[18,21]],
		"/[companySlug]/taxonomy/[reportId]/financial/[entityId]/[activityId]": [~108,[18,21]],
		"/[companySlug]/taxonomy/[reportId]/manage": [~111,[18]],
		"/[companySlug]/taxonomy/[reportId]/manage/[entityId]": [~112,[18]],
		"/[companySlug]/taxonomy/[reportId]/report": [113,[18]],
		"/[companySlug]/taxonomy/[reportId]/start": [~114,[18]],
		"/[companySlug]/taxonomy/[reportId]/summary": [115,[18,22]],
		"/[companySlug]/taxonomy/[reportId]/summary/downloads": [~116,[18,22]],
		"/[companySlug]/taxonomy/[reportId]/summary/kpi": [~117,[18,22]],
		"/[companySlug]/ungc": [120,[2,24],[3]],
		"/[companySlug]/ungc/category": [~121,[2,24],[3]],
		"/[companySlug]/ungc/themes": [~122,[2,24],[3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.svelte';